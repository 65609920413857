<template>
  <div class="settings BG0 m-8 pt-4 lg:block" style="border: 1px solid #e6e8f0">
    <div
      class="
        flex
        mx-auto
        justify-center
        w-4/5
        gap-5
        px-5
        cursor-pointer
        relative
      "
    >
      <div @click="routeUrl()" class="flex items-center">
        <div :class="store.showAll ? '' : ''">
          <BurgerSvg />
        </div>
        <p
          class="mt-2 text-[27px] N700 font-bold hidden lg:block mr-12"
          :class="{ 'ml-4': store.showAll }"
          v-if="store.showAll"
        >
          AmaliTech
        </p>
      </div>
      <span
        class="
          max-[1200px]:hidden
          absolute
          h-6
          w-6
          rounded-full
          flex
          items-center
          py-0
          px-2
          shadow-md
          z-40
          BG0
          m-8
          top-2
        "
        :style="{ left: store.showAll ? '13rem' : '1.75rem' }"
        @click="store.showAll = !store.showAll"
      >
        <ArrowLeftIcon v-if="store.showAll" />
        <ArrowRightIcon v-else />
      </span>
    </div>
    <div>
      <div class="flex flex-col h-[20vh]">
        <div class="pt-5 px-3">
          <hr />
        </div>
        <div class="">
          <div class="flex flex-col justify-center px-3">
            <NavigationComponent
              :item="{ label: 'Staff', route: homeRoutes }"
              @mouseover="toggleOnStaffDirectory(true)"
              @mouseout="toggleOnStaffDirectory(false)"
            >
              <StaffLogo
                :color="
                  onStaffDirectory ||
                  $route.path.startsWith('/add-employee') ||
                  $route.path.startsWith('/view-employee') ||
                  $route.path.startsWith('/edit-profile') ||
                  $route.path === '/all-employees' ||
                  $route.path === '/' ||
                  $route.path === '/home'
                    ? '#DD5928'
                    : '#8F95B2'
                "
              />
            </NavigationComponent>
          </div>
        </div>

        <div class="px-3">
          <hr />
        </div>

        <div class="px-3" v-if="viewSettings">
          <div>
            <NavigationComponent
              :item="{ label: 'Settings', route: settingsRoutes }"
              @mouseover="toggleOnSettings(true)"
              @mouseout="toggleOnSettings(false)"
            >
              <SettingsSVG
                :color="
                  onSettings ||
                  $route.path.startsWith('/updateaccesslevel') ||
                  $route.path.startsWith('/create-access-level') ||
                  $route.name === 'departments' ||
                  $route.name === 'positions' ||
                  $route.name === 'employeedata' ||
                  $route.name === 'Employment Types' ||
                  $route.name === 'Seniority Level' ||
                  $route.name === 'Access Level' ||
                  $route.name === 'Id Prefix'
                    ? '#DD5928'
                    : '#8F95B2'
                "
              />
            </NavigationComponent>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useAccessStore } from "@/store/store_access";
import SettingsSVG from "@/assets/template-icons/SettingsIcon.vue";
import StaffLogo from "@/assets/template-icons/StaffLogo.vue";
import NavigationComponent from "./NavigationComponent.vue";
import { ref, computed } from "vue";
import { useStore } from "@/store";
import ArrowRightIcon from "@/assets/template-icons/ArrowRightIcon.vue";
import ArrowLeftIcon from "@/assets/template-icons/ArrowLeftIcon.vue";
import { getToken } from "@/services/auth";
import config from "@/config/config";
import BurgerSvg from "@/assets/template-icons/BurgerSvg.vue";
import { useRoute } from "vue-router";

const emits = defineEmits(["appSelected"]);
const Access = useAccessStore();
const store = useStore();
const route = useRoute();

const routeUrl = () => {
  const token: string | null = getToken();
  if (token) {
    window.location.href = `${config.mainPageLink}`;
  }
};

const homeRoutes = computed(() => {
  if (route.path === "/all-employees") {
    return "/all-employees";
  }
  if (route.path === "/home") {
    return "/home";
  }
  if (route.path === "/") {
    return "/";
  }
  if (route.path.startsWith("/view-employee")) {
    return "/all-employees";
  }
  if (route.path.startsWith("/edit-profile")) {
    return "/all-employees";
  }
  return "/all-employees";
});

const settingsRoutes = computed(() => {
  const routesMap: Record<string, string> = {
    "/departments": "/departments",
    "/positions": "/positions",
    "/employeedata": "/employeedata",
    "/employment-types": "/employment-types",
    "/idprefix": "/idprefix",
    "/seniority-level": "/seniority-level",
    "/access-level": "/access-level",
    "/updateaccesslevel": "/updateaccesslevel",
    "/create-access-level": "/create-access-level"
  };

  return routesMap[route.path] || "/access-level";
});

const viewSettings = computed(() => {
  const settingsAccess = Access?.permissions?.settings?.employee_management;
  return (
    settingsAccess?.view_departments ||
    settingsAccess?.view_seniority_level ||
    settingsAccess?.view_employment_types ||
    settingsAccess?.view_access_levels ||
    settingsAccess?.view_id_prefix ||
    settingsAccess?.view_position
  );
});
const onStaffDirectory = ref(false);
const onSettings = ref(false);

const toggleOnStaffDirectory = (status: boolean) => {
  onStaffDirectory.value = status;
};
const toggleOnSettings = (status: boolean) => {
  onSettings.value = status;
};
</script>
<style scoped>
.settings {
  height: 100vh;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  margin: 0;
  position: relative;
  z-index: 10;
  color: #474d66;
  background-color: white;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.settings::-webkit-scrollbar {
  display: none;
}

ul {
  margin: 19px 0;
}

li {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.H700 {
  z-index: 2;
}
</style>
